
export const rest = `https://5lbq4iyv36.execute-api.ap-southeast-1.amazonaws.com/develop/`


let siswaUrl = (process.env.REACT_APP_FIREBASE_PROD === "YES") ? 'https://pakbudi.id/' : 'https://develop.pakbudi.id/';

if (process.env.NODE_ENV === 'development') {
    siswaUrl = 'http://localhost:3000/'
}

export const siswa = siswaUrl;