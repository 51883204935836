export const employee = [
  "197403091999031001",
  "196605111992031001",
  "198304102002121003",
  "196610241994032005",
  "196608081990032001",
  "197301121995101001",
  "196806031994031001",
  "197108071994031001",
  "197106111991031002",
  "196906141999031001",
  "197211251993032001",
  "197011241992031001",
  "197704222006041001",
  "196811161994031001",
  "196804091989032001",
  "196808181999031001",
  "198201082001121001",
  "197011301992031001",
  "198304192009011001",
  "198004042009011006",
  "198206272001121002",
  "198407192009011003",
  "198712252009122004",
  "197307191999031001",
  "197704081999032001",
  "198405252002121001",
  "197903272006041001",
  "198206292006041001",
  "198509022012121001",
  "198709232012121001",
  "198801052012121001",
  "198801212012121003",
  "198805312012121003",
  "198811212012122001",
  "198912152012122002",
  "198907032012122002",
  "198905262012121001",
  "198401072002121004",
  "199005022009012001",
  "198902192008012001",
  "198902182014021002",
  "198904282014022004",
  "198405042009121002",
  "198811192012122001",
  "198707282006042001",
  "198107262002121001",
  "198404302014021001",
  "196801251991031001",
  "197004241992032001",
  "196812061994032001",
  "198710272010122001",
  "197408181995101001",
  "198601152012122002",
  "198311162012122001",
  "198710082012122001",
  "198903072014022005",
  "196605121999031001",
  "197002201999031002",
  "197904041999031001",
  "196606142000121001",
  "198803112017121001",
  "199109242010122001",
  "199401232017122003",
  "197907062006042001",
  "198708272017122001",
  "199302172017122001",
  "199109222017122001",
  "199406302017121002",
  "199209162017121002",
  "199408082017122001",
  "199212112017121001",
  "199209102017122001",
  "199408292017122001",
  "199212112017122001",
  "199405272017121005",
  "199405202017121001",
  "198910232017122001",
  "199302252017122002",
  "199303172017122002",
  "198907262017121001",
  "199212192017121001",
  "199109192017121001",
  "199206072017121002",
  "199405092017122002",
  "199212302017121001",
  "197103262001121001",
  "198105032001122001",
  "198409032009121006",
  "199302012017121001",
  "199404292017122001",
  "199310302017122001",
  "198807162017121001",
  "198910042017122001",
  "199407192017122001",
  "199405022017121009",
  "199405292017121002",
  "199403302017122001",
  "199209232017121001",
  "199007162017121001",
  "199005092017122002",
  "199511232017121001",
  "199105142017122001",
  "199512172017121001",
  "199009242017121001",
  "198601062017121001",
  "199105222017122001",
  "198609122017121001",
  "199301052017122001",
  "199209102017121001",
  "199409122017122001",
  "199410222017121001",
  "199602192017122001",
  "199302102017121001",
  "199501042017122001",
  "199402092017121001",
  "198911172017121001",
  "199106282017122001",
  "198604162017121001",
  "198806262017121001",
  "199104082017122002",
  "198907042017121001",
  "199408012017122001",
  "199208222017122001",
  "198505092017121001",
  "199101152017121008",
  "198803152017121001",
  "199108192017122001",
  "199406102017122002",
  "198607072017121001",
  "199502152017122002",
  "199012182017122001",
  "199508202017122001",
  "199312302017121001",
  "199407112017121001",
  "198804132017122001",
  "199505052017121001",
  "199312102017122002",
  "199406212017121001",
  "199408012017122004",
  "198909102017121002",
  "199008202017121002",
  "199211152017121001",
  "199002282017121002",
  "199404142016081001",
  "198803132009121003",
  "199305042017122001",
  "199403102017121002",
  "199307142017122003",
  "198408232017121001",
  "198506192010122001",
  "199312242017121001",
  "199108062017121001",
  "199305152017121003",
  "199506142017122001",
  "199205092017121002",
  "199303242017121001",
  "199209302017121001",
  "198507062017121001",
  "199305052017121001",
  "199307112017121001",
  "199507152017122001",
  "199407072017122002",
  "198904092017122001",
  "199209212017121006",
  "199403102017121001",
  "199109132017121001",
  "198010182009121002",
  "198811252010122002",
  "198605242010122005",
  "199104302017121002",
  "198607102017121001",
  "198809202017121001",
  "199501072017122004",
  "199407292017122002",
  "198506132017121002",
  "199102042017121002",
  "199108062017121003",
  "199112222017122003",
  "198206122002121001",
  "198810052017121001",
  "198605312017121001",
  "198810142017121001",
  "199011062017121001",
  "199402062017121001",
  "198609192017122001",
  "199109172017121001",
  "198904172017121001",
  "196912302003121001",
  "197910042003121001",
  "199706192021011002",
  "199709022021011001",
  "199805302021011001",
  "199806212021011001",
  "199204272010122002",
  "198410102010121004",
  "198910312010121003",
  "199212062012122001",
  "199706282019011001",
  "199206072010122001",
  "198209092010121001",
  "199609092022011001",
  "199701182022011001",
  "199708052022011001",
  "199802052022011001",
  "199901302022011002",
  "199902102022012001",
  "199903202022011002",
  "200001132022011002",
  "200001222022011001",
  "199901102022011001",
  "197307192006041001",
  "198208142010122002",
  "199712042023011001",
  "200003232023011001",
  "199901232023011001",
  "199909072023012001",
  "199911302023011001",
  "199912112023012002",
  "200007262023011001",
  "200008172023011001",
  "200012162023012001",
  "197507272006041001",
  "198105222010121001",
  "199109202015032004",
  "199105062010122002",
  "199411082019011001",
  "199909122019011001",
  "200010102020121001",
  "200007152020121002",
  "200106232022032001",
  "200305152022032001",
  "200307142022032001",
];
