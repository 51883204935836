import axios from "axios";
import { useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import { rest } from "../config/urls";
const restReq = axios.create({
  baseURL: rest,
});

export default function useRest(props) {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  useDeepCompareEffect(() => {
    const { url, method = "get", data = {}, params = {} } = props;
    //console.log(params, Object.values(params).every(val => val !== ''), data, Object.values(data))
    const paramsValue = Object.values(params);
    const dataValue = Object.values(data);

    if (
      (paramsValue.length > 0 && paramsValue.every((val) => val !== "")) ||
      (dataValue.length > 0 && dataValue.every((val) => val !== ""))
    ) {
      const makeRequest = async () => {
        setLoading(true);
        try {
          const response = await restReq({
            method,
            url,
            data,
            params,
          });

          setResponse(response);
        } catch (error) {
          setError(error);
        }
        setLoading(false);
      };

      makeRequest();
    }
  }, [props]);

  return [response, loading, error];
}
