import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    container: {
        padding: '0 !important'
    },
    appBar: {
        borderBottom: 'solid 5px #106aff',
    },
    logo: {
        width: 150,
        height: 'auto',
        display: 'block',
        margin: 'auto',
        padding: 10,
    },
    heroTitle: {
        fontSize: 17,
    },
    regBody: {
        margin: '20px auto 10px',
        width: '75%',
        '@media(min-width: 400px)': {
            width: '70%',
        },
        '@media(min-width: 500px)': {
            width: '65%',
        },
        '@media(min-width: 600px)': {
            width: '60%',
        },
        '@media(min-width: 700px)': {
            width: '50%',
        },
    },
    formWrap: {
        marginTop: 10,
    },
    formRow: {
        width: '100%',
        marginBottom: 10,
    },
    textField: {
        width: '100%',
    },
    btnWrap: {
        marginTop: 20,
    }
}))