//import React, { useContext, useEffect, useState } from 'react';
//import firebase from 'firebase/app';
//import 'firebase/auth';
//import 'firebase/firestore';
//import 'firebase/functions';
//import firebaseConfig from '../config/firebase';
//import { useAuthState } from 'react-firebase-hooks/auth';
//import AppLoading from './AppLoading';

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import React, { useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import firebaseConfig from "../config/firebase";
import AppLoading from "./AppLoading";

// Initialize Firebase app
const firebaseApp = initializeApp(firebaseConfig);
//firebase.initializeApp(firebaseConfig);

const FirebaseContext = React.createContext();
export function useFirebase() {
  return useContext(FirebaseContext);
}

function FirebaseProvider(props) {
  const auth = getAuth(firebaseApp);
  const firestore = getFirestore(firebaseApp);
  const functions = getFunctions(firebaseApp);
  //    const auth = firebase.auth();
  //    const firestore = firebase.firestore();
  //    const functions = firebase.functions();

  const [user, loading, error] = useAuthState(auth);

  const [isCacheEnabled, setCacheEnabled] = useState(false);

  useEffect(() => {
    const enablePersistence = async () => {
      try {
        //await firebase.firestore().enablePersistence()
        setCacheEnabled(true);
        console.log("cache enabled");
      } catch (err) {
        console.log(err.code);
      }
    };
    enablePersistence();
  }, []);

  if (!isCacheEnabled) {
    return <AppLoading />;
  }
  return (
    <FirebaseContext.Provider
      value={{
        auth,
        firestore,
        functions,
        user,
        loading,
        error,
        FieldValue: firestore.FieldValue,
      }}
    >
      {props.children}
    </FirebaseContext.Provider>
  );
}

export default FirebaseProvider;
