import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    main: {
        display: 'flex',
        overflow: 'hidden',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
    },
    loadingBox: {
        textAlign: 'center',
        width: 212,
    },
    siteName: {
        color: theme.palette.primary.main,
        textAlign: 'center'
    },
    loadingBar: {
        margin: '10px auto 0px'
    }
}))