import React from 'react';
import { BrowserRouter as Router, Route, Switch, } from 'react-router-dom';
import './App.css';

// material-ui themes
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import theme from './theme';
import CssBaseline from '@material-ui/core/CssBaseline';

import Home from './pages/home';
import Game from './pages/game';
import Result from './pages/game/hasilTest';
import NotFound from './pages/404';
import Register from './pages/register';
import Login from './pages/login';
import FirebaseProvider from './components/FirebaseProvider';
import PrivateRoute from './components/PrivateRoute';
import { SnackbarProvider } from "notistack";
const pakBudiTheme = createMuiTheme(theme);

function App() {
  return (
    <React.Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={pakBudiTheme}>
        <div className="App">
          <div className="Content">
            <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
              <FirebaseProvider>
                <Router>
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/game" component={Game} />
                    <Route path="/result/:symbol" component={Result} />
                    <PrivateRoute path="/register" component={Register} />
                    <Route path="/login" component={Login} />
                    <Route component={NotFound} />
                  </Switch>
                </Router>
              </FirebaseProvider>
            </SnackbarProvider>
          </div>
        </div>
      </MuiThemeProvider>
    </React.Fragment>
  );
}

export default App;