// React
import React, { useEffect, useState } from "react";

// Styles
import useStyles from "./styles/index";

// Images
import logo from "../../images/new-logo-w-tmb.png";
import { baseUrl } from "../../images/s3";

// Material-UI
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import SaveAltIcon from "@material-ui/icons/SaveAlt";

// Utils
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import { useSnackbar } from "notistack";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import { StickyShareButtons } from "sharethis-reactjs";
import { dataURItoFile } from "../../utils/converter";
import results from "./data/results.json";

// Firebase
import { Box, Tab, Tabs } from "@material-ui/core";
import { doc, onSnapshot } from "firebase/firestore";
import { useFirebase } from "../../components/FirebaseProvider";

function HasilTest(props) {
  const classes = useStyles();
  const { firestore } = useFirebase();
  const { match, location } = props;
  const { symbol } = match.params;
  const [detail, setDetail] = useState(false);
  const [banner, setBanner] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(firestore, "events", "banner"),
      (doc) => {
        if (doc.exists()) {
          setBanner(doc.data());
        }
      }
    );
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { enqueueSnackbar } = useSnackbar();
  if (!symbol || !Object.keys(results).includes(symbol.toUpperCase())) {
    return <Redirect to="/" />;
  }
  const ucSymbol = symbol.toUpperCase();
  const result = results[ucSymbol];

  const handleExpand = () => {
    setDetail(true);
  };

  const downloadBody = async (e) => {
    try {
      const canvas = await html2canvas(document.body, {
        allowTaint: false,
        useCORS: true,
      });
      const file = dataURItoFile(canvas.toDataURL());
      saveAs(file, `hasil-tmb-${symbol}.png`);
    } catch (e) {
      enqueueSnackbar(e.message);
    }
  };

  const handleChangeTab = (_, newValue) => {
    setSelectedTab(newValue);
  };

  const nama = location?.state?.nama ?? "";
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="87% Orang salah pilih jurusan kuliah!! Ayo cari tahu apa bakat kamu disini!"
        />
        <meta
          property="og:title"
          content={`Aku adalah seorang ${ucSymbol}, bagaimana dengan kamu?`}
        />
        <meta
          property="og:description"
          content="87% Orang salah pilih jurusan kuliah!! Ayo cari tahu apa bakat kamu disini!"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://tesminatbakat.pakbudi.id/result/${symbol}`}
        />
        <meta property="og:image" content={`${baseUrl}${symbol}.png`} />
        <meta property="og:site_name" content="Tes Minat Bakat Online Gratis" />
      </Helmet>
      <Container
        className={classes.containerCenter}
        maxWidth="xl"
        component="main"
      >
        <div className={classes.root}>
          <IconButton
            onClick={downloadBody}
            className={classes.saveBtn}
            data-html2canvas-ignore
          >
            <SaveAltIcon />
          </IconButton>
          <div className={classes.hasilTestHead}>
            <img
              src={logo}
              alt=""
              className={classes.logo}
              crossOrigin="anonymous"
            />
          </div>
          <div className={classes.hasilTestBody}>
            {nama && (
              <>
                <h3 className={classes.announcePerson}>{nama}</h3>
              </>
            )}
            <div className={classes.resultContainer}>
              <div className={classes.resultImgWrap}>
                <img
                  src={`${baseUrl}${symbol}.png`}
                  alt=""
                  className={classes.resultImg}
                  crossOrigin="anonymous"
                />
              </div>
              <div className={classes.resultTextContainer}>
                <h4 className={classes.resultTitle}>{result.symbol}</h4>
                <p className={classes.resultSubtitle}>({result.short})</p>
              </div>
            </div>
            <div className={classes.resultExp}>
              <p className={classes.whiteText}>Kamu adalah calon:</p>
              <p className={classes.blackText}>{result.profession}</p>
            </div>
            <div className={classes.figureExp}>
              <p className={classes.whiteText}>
                Tokoh sukses yang mirip kepribadian kamu:
              </p>
              <p className={classes.blackText}>{result.tokoh}</p>
            </div>

            {!detail && (
              <div
                className={`${classes.dividerBtn} ${classes.dividerTop}`}
                data-html2canvas-ignore
              >
                <span className={classes.line}></span>
                <Button
                  id="detail-trigger"
                  className={classes.detailTrigger}
                  onClick={handleExpand}
                >
                  Lebih detail
                </Button>
              </div>
            )}

            {detail && (
              <>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs value={selectedTab} onChange={handleChangeTab}>
                    <Tab label="Umum" id="tab-0" aria-controls="tabpanel-0" />
                    <Tab label="Khusus" id="tab-1" aria-controls="tabpanel-1" />
                  </Tabs>
                </Box>
                <CustomTabPanel value={selectedTab} index={0}>
                  <div className={classes.detailWrap}>
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>
                          Penjelasan Karakter Kamu
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography
                          component="ol"
                          className={classes.panelDetail}
                          dangerouslySetInnerHTML={{
                            __html: result.description,
                          }}
                        />
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography className={classes.heading}>
                          Jabatan yang cocok untuk kamu
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography className={classes.panelDetail}>
                          {result.jabatan}
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography className={classes.heading}>
                          Jurusan Kuliah yang Cocok Buat Kamu
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography className={classes.panelDetail}>
                          {result.jurusan}
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography className={classes.heading}>
                          Profesi yang Cocok Buat Kamu
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography
                          variant="body1"
                          className={`${classes.panelDetail} ${classes.panelDetailCapital}`}
                        >
                          {result.profession}
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography className={classes.heading}>
                          Saran Pengembangan
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography
                          variant="body1"
                          className={classes.panelDetail}
                        >
                          {result.improvement}
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography className={classes.heading}>
                          Tipe Kepribadian Orang Lain yang Cocok Denganmu
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography
                          variant="body1"
                          className={classes.panelDetail}
                        >
                          {result.partner}
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography className={classes.heading}>
                          Contoh Orang Sukses Dengan Kepribadian yang Sama
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography
                          variant="body1"
                          className={classes.panelDetail}
                        >
                          {result.tokoh}
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </div>
                </CustomTabPanel>
                <CustomTabPanel value={selectedTab} index={1}>
                  <div className={classes.detailWrap}>
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography className={classes.heading}>
                          Apa itu {ucSymbol}?
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography
                          variant="body1"
                          className={classes.panelDetail}
                        >
                          {result.whatIs}
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography className={classes.heading}>
                          Personal Growth
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography
                          variant="body1"
                          className={classes.panelDetail}
                        >
                          {result.personalGrowth}
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography className={classes.heading}>
                          Bagaimana {ucSymbol} Belajar?
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography
                          variant="body1"
                          className={classes.panelDetail}
                        >
                          {result.learning}
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography className={classes.heading}>
                          Pekerjaan atau Kuliah?
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography
                          variant="body1"
                          className={classes.panelDetail}
                        >
                          {result.workOrCollege}
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography className={classes.heading}>
                          Professional Development
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography
                          variant="body1"
                          className={classes.panelDetail}
                        >
                          {result.professionalDevelopment}
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography className={classes.heading}>
                          Pekerjaan Alternatif
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography
                          variant="body1"
                          className={classes.panelDetail}
                        >
                          {result.pekerjaanAlternatif}
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                      >
                        <Typography className={classes.heading}>
                          Kecocokan
                        </Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography
                          variant="body1"
                          className={classes.panelDetail}
                        >
                          {result.kecocokan}
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </div>
                </CustomTabPanel>
                <div className={classes.detailBody} data-html2canvas-ignore>
                  <div className={classes.whiteText}>
                    <p>
                      Klik tombol diatas untuk tahu lebih dalam tentang karakter
                      kamu, jurusan yang cocok, pekerjaan yang cocok, teman yang
                      cocok, dan lainnya
                    </p>
                  </div>
                </div>
              </>
            )}
            <div
              data-html2canvas-ignore
              className={classes.hasilTestHead}
              style={{
                margin: "10px 0px",
                display: "grid",
                placeContent: "center",
              }}
            >
              <a href={`https://pakbudi.id/checkout`}>
                <img
                  src={banner?.tmbPhotoUrl}
                  alt=""
                  crossOrigin="anonymous"
                  width="300px"
                />
              </a>
            </div>
          </div>
        </div>
      </Container>
      <div data-html2canvas-ignore>
        <StickyShareButtons
          config={{
            alignment: "left", // alignment of buttons (left, right)
            color: "social", // set the color of buttons (social, white)
            enabled: true, // show/hide buttons (true, false)
            font_size: 16, // font size for the buttons
            hide_desktop: false, // hide buttons on desktop (true, false)
            labels: "counts", // button labels (cta, counts, null)
            language: "en", // which language to use (see LANGUAGES)
            min_count: 0, // hide react counts less than min_count (INTEGER)
            networks: [
              // which networks to include (see SHARING NETWORKS)
              "whatsapp",
              "facebook",
              "twitter",
              "instagram",
            ],
            padding: 12, // padding within buttons (INTEGER)
            radius: 4, // the corner radius on each button (INTEGER)
            show_total: true, // show/hide the total share count (true, false)
            show_mobile: true, // show/hide the buttons on mobile (true, false)
            show_toggle: true, // show/hide the toggle buttons (true, false)
            size: 48, // the size of each button (INTEGER)
            top: 180, // offset in pixels from the top of the page

            // OPTIONAL PARAMETERS
            // url: 'https://tesminatbakat.pakbudi.id/resutl', // (defaults to current url)
            image: `${baseUrl}${symbol}.png`, // (defaults to og:image or twitter:image)
            description:
              "87% Orang salah pilih jurusan kuliah!! Ayo cari tahu apa bakat kamu disini!", // (defaults to og:description or twitter:description)
            title: `Aku adalah seorang ${ucSymbol}, bagaimana dengan kamu?`, // (defaults to og:title or twitter:title)
            // message: 'custom email text',     // (only for email sharing)
            // subject: 'custom email subject',  // (only for email sharing)
            // username: 'custom twitter handle' // (only for twitter sharing)
          }}
        />
      </div>
    </>
  );
}

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

export default HasilTest;
