const font = "'Poppins', sans-serif";
const theme = {
  direction: "ltr",

  palette: {
    primary: {
      main: "#30bcec",
      light: "",
      dark: "#0e5f96",
      contrastText: "#fff",
    },
    secondary: {
      main: "#fdb31b",
      light: "",
      dark: "",
      contrastText: "#000",
    },
    textColor: {
      gray: "#777",
      gray2: "#ddd",
      gray3: "#eee",
      gray4: "#f2f4f4",
      gray5: "#0000008a",
      green: "#19b821",
      blue: "#2757ca",
      blue2: "#32bdea",
    },
  },

  typography: {
    fontFamily: font,
    useNextVariants: true,
  },
};

export default theme;
