const BASE64_MARKER = ';base64,';

export const isDataURI = function (url) {
    return url.split(BASE64_MARKER).length === 2;
}

export const dataURItoFile = function (dataURI) {
    if (!isDataURI(dataURI)) { return false; }

    // Format of a base64-encoded URL:
    // data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAYAAAAEOCAIAAAAPH1dAAAAK
    const mime = dataURI.split(BASE64_MARKER)[0].split(':')[1];
    const filename = 'dataURI-file-' + (new Date()).getTime() + '.' + mime.split('/')[1];
    const bytes = atob(dataURI.split(BASE64_MARKER)[1]);
    const writer = new Uint8Array(new ArrayBuffer(bytes.length));

    for (let i = 0; i < bytes.length; i++) {
        writer[i] = bytes.charCodeAt(i);
    }

    return new File([writer.buffer], filename, { type: mime });
}