// React
import React from "react";

// Router
import { Redirect } from "react-router-dom";

// Components
import AppLoading from "../../components/AppLoading";

// Firebase
import { useFirebase } from "../../components/FirebaseProvider";

function Login({ location }) {
  // Firebase
  const { user, loading } = useFirebase();

  // Condition Loading
  if (loading) {
    return <AppLoading />;
  }

  // conditions when there are users for SignIn with form
  if (user) {
    const redirectTo =
      location.state && location.state.from
        ? location.state.from.pathname
        : "/lengkapiData";
    return <Redirect to={redirectTo} />;
  }

  return <Redirect to="/" />;
}

export default Login;
