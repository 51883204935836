import React from 'react';
import ContentLoader from "react-content-loader";

function ImageLoader({ width, height }) {

    return (
        <div id='content-loader-div' style={{ width: width, height: height, margin: 'auto' }}>
            <ContentLoader
                height={height}
                width={width}
                speed={2}
                primaryColor="#f3f3f3"
                secondaryColor="#ecebeb"
            >
                <rect x="4.28" y="11.67" rx="0" ry="0" width={width} height={height} />
            </ContentLoader>
        </div>
    );
}

export default ImageLoader;