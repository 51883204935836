import React, { useEffect, useState } from "react";

// import komponen material ui
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Slide from "@material-ui/core/Slide";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";

import Toolbar from "@material-ui/core/Toolbar";
import statements from "./data/statements.json";

import LinearProgress from "@material-ui/core/LinearProgress";
// import styles
import pbLogo from "../../images/new-logo-blue.png";
import useStyles from "./styles.js";

import isEmail from "validator/lib/isEmail";

// app components
import SelectKota from "../../components/AppSelect/kota";
import SelectProvinsi from "../../components/AppSelect/provinsi";

import { getAuth } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
// utils for game
import calculateResult from "./calculate";

import shuffle from "lodash/shuffle";
import Img from "react-image";
import ImageLoader from "../../components/ImageLoader";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import { useSnackbar } from "notistack";
import { baseUrl, logo } from "../../images/s3";
import { employee } from "./data/imigrasiEmployee";
import results from "./data/results.json";

// buat sebuah fungsi komponen dengan nama NotFound
function Game({ history }) {
  const classes = useStyles();
  // const { executeRecaptcha } = useGoogleReCaptcha();
  const functions = getFunctions();
  const auth = getAuth();
  const [started, setStarted] = useState(false);
  const [slide, setSlide] = useState({
    direction: "left",
    in: false,
  });
  const [index, setIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [done, setDone] = useState(false);

  const [isSubmitting, setSubmitting] = useState(false);

  const [indexes, setIndexes] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const [form, setForm] = useState({
    nama: "",
    nip: "",
    sekolah: {
      nama: "",
      sekolah_id: "",
    },

    provinsi: {
      nama: "",
      provinsi_id: "",
    },
    kota: {
      nama: "",
      kota_id: "",
    },
    email: "",
    no_hp: "",
    kelas: "",
  });

  const [error, setError] = useState({
    nama: "",
    nip: "",
    sekolah: "",
    provinsi: "",
    kota: "",
    email: "",
    no_hp: "",
    kelas: "",
  });

  useEffect(() => {
    const indexes = shuffle(Array.from(statements.keys()));
    setIndexes(indexes);
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((form) => ({
      ...form,
      [name]: value,
    }));

    setError((error) => ({
      ...error,
      [name]: "",
    }));
  };

  const handleAnswer = (e) => {
    setAnswers((answers) => ({
      ...answers,
      [e.target.name]: e.target.value,
    }));

    setSlide({
      direction: "right",
      in: false,
    });
    setTimeout(() => {
      if (index >= 59) setDone(true);
      else setIndex((index) => index + 1);
      setSlide({
        direction: "left",
        in: true,
      });
    }, 300);
  };

  const validate = async () => {
    const newError = { ...error };
    const { email, no_hp, nama, sekolah, provinsi, kota, nip } = form;

    if (!email) {
      newError.email = "Email wajib diisi";
    } else if (!isEmail(email)) {
      newError.email = "Email tidak valid";
    }

    if (!nip) {
      newError.nip = "NIP wajib diisi";
    }

    if (!no_hp) {
      newError.no_hp = "WhatsApp wajib diisi";
    }

    if (!nama) {
      newError.nama = "Nama wajib diisi";
    }

    if (!sekolah.nama) {
      newError.sekolah = "Sekolah wajib diisi";
    }

    if (!provinsi.provinsi_id) {
      newError.provinsi = "Provinsi wajib diisi";
    }

    if (!kota.kota_id) {
      newError.kota = "Provinsi wajib diisi";
    }
    if (!form.kelas) {
      newError.kelas = "Kelas wajib diisi";
    }

    return newError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const findError = await validate();

    if (Object.values(findError).some((m) => m !== "")) {
      setError(findError);
    } else {
      // const token = await executeRecaptcha("tmb_register");
      // const verifyRecaptcha = httpsCallable(functions, "verifyRecaptcha");

      // const recaptcha = await verifyRecaptcha({ token });
      // if (
      //   recaptcha.data.action !== "tmb_register" ||
      //   recaptcha.data.score <= 0.5
      // ) {
      //   setError({ nama: "Terjadi kesalahan! Silahkan coba lagi lain kali" });
      //   return false;
      // }
      const nip = form.nip.replace(/ /g, "");
      const index = employee.findIndex((item) => item === nip);
      if (index === -1) {
        setError({
          nip: "NIP tidak terdaftar! Silahkan masukkan NIP yang benar",
        });
        return false;
      }

      setSubmitting(true);
      const hasil_tesMinatBakat = calculateResult(answers);
      const mbti = results[hasil_tesMinatBakat];
      const formedData = {
        ...form,
        is_imigrasi_data: true,
        jabatan: mbti.jabatan,
        personal_growth: mbti.personalGrowth,
        professional_development: mbti.professionalDevelopment,
      };

      try {
        const tmbRegister = httpsCallable(functions, "tmbRegister");

        const result = await tmbRegister({
          ...formedData,
          isKelas12: false,
          hasil_tesMinatBakat,
          recaptcha: 0.9,
        });
        if (result.data.customToken)
          await auth.signInWithCustomToken(result.data.customToken);
        setSubmitting(false);
        history.push(`/result/${hasil_tesMinatBakat.toLowerCase()}`, {
          nama: form.nama,
          email: result.data.user.email,
          password: result.data.user.password,
        });
      } catch (e) {
        setSubmitting(false);
        const existsCode = [
          "auth/email-already-in-use",
          "auth/email-already-exists",
        ];
        if (existsCode.includes(e.details.code)) {
          enqueueSnackbar(
            "Sekarang, anda sudah terdaftar di aplikasi PakBudi. Silahkan login untuk melihat detail hasil tes minat bakat kamu. Gunakan fitur lupa password untuk membuat password baru.",
            {
              persist: true,
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }
          );
        }
        history.push(`/result/${hasil_tesMinatBakat.toLowerCase()}`, {
          nama: form.nama,
          code: e.details.code,
        });
      }
    }
  };

  const appbar = (
    <AppBar className={classes.appBar} position="static">
      <Toolbar>
        <img src={logo} alt="logo" className={classes.logo} />
      </Toolbar>
    </AppBar>
  );

  if (!started) {
    return (
      <>
        {appbar}
        <Container className={classes.container} maxWidth="xs" component="main">
          <div className={classes.guide}>
            <Typography className={classes.title} variant="h3">
              {" "}
              PERHATIAN
            </Typography>
            <ol className={classes.list}>
              <li className={classes.listItem}>
                Pilih pernyataan yang paling mewakili kepribadianmu pada salah
                satu gambar yang tersedia.
              </li>
              <li className={classes.listItem}>
                Tidak ada jawaban yang benar atau salah, maka jawablah dengan
                objektif.
              </li>
              <li className={classes.listItem}>
                {" "}
                Bila sudah siap tap tombol mulai tes dibawah ini:
              </li>
            </ol>
            <img
              className={classes.frontImg}
              src={`${baseUrl}avatar.png`}
              alt="avatar"
            />

            <Button
              className={classes.startBtn}
              size="large"
              onClick={() => {
                setStarted(true);
                setSlide({
                  direction: "left",
                  in: true,
                });
              }}
              variant="contained"
              color="primary"
            >
              {" "}
              Mulai Test Sekarang
            </Button>
          </div>
        </Container>
      </>
    );
  }

  if (done) {
    return (
      <>
        {" "}
        {appbar}
        <Slide direction={slide.direction} in={slide.in}>
          <Container
            fixed
            className={classes.container}
            maxWidth="xs"
            component="main"
          >
            <div className={classes.formBody}>
              <form onSubmit={handleSubmit}>
                <TextField
                  disabled={isSubmitting}
                  id="nama"
                  name="nama"
                  label="Nama"
                  value={form.nama}
                  onChange={handleChange}
                  helperText={error.nama}
                  error={!!error.nama}
                  fullWidth
                  className={classes.formInput}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  disabled={isSubmitting}
                  id="nip"
                  name="nip"
                  label="NIP*"
                  value={form.nip}
                  onChange={handleChange}
                  helperText={error.nip}
                  error={!!error.nip}
                  fullWidth
                  className={classes.formInput}
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  //  variant="outlined"
                  disabled={isSubmitting}
                  id="email"
                  name="email"
                  label="Email*"
                  type="email"
                  // className={classes.textField}
                  value={form.email}
                  className={classes.formInput}
                  onChange={handleChange}
                  helperText={error.email}
                  error={!!error.email}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />

                <TextField
                  // variant="outlined"
                  disabled={isSubmitting}
                  id="no_hp"
                  name="no_hp"
                  label="No WhatsApp Aktif*"
                  //className={classes.textField}
                  value={form.no_hp}
                  onChange={handleChange}
                  className={classes.formInput}
                  helperText={error.no_hp}
                  error={!!error.no_hp}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <SelectProvinsi
                  className={classes.formInput}
                  value={{
                    label: form.provinsi.nama,
                    value: form.provinsi.provinsi_id,
                  }}
                  onChange={({ value, label }) => {
                    handleChange({
                      target: {
                        value: {
                          nama: label,
                          provinsi_id: value,
                        },
                        name: "provinsi",
                      },
                    });
                    handleChange({
                      target: {
                        value: {
                          nama: "",
                          kota_id: "",
                        },
                        name: "kota",
                      },
                    });
                    handleChange({
                      target: {
                        value: {
                          nama: "",
                          sekolah_id: "",
                        },
                        name: "sekolah",
                      },
                    });
                  }}
                  TextFieldProps={{
                    margin: "normal",
                    //   variant: "outlined",
                    disabled: isSubmitting,
                    helperText: error.provinsi,
                    error: !!error.provinsi,
                  }}
                />

                <SelectKota
                  className={classes.formInput}
                  value={{
                    label: form.kota.nama,
                    value: form.kota.kota_id,
                  }}
                  onChange={({ value, label }) => {
                    handleChange({
                      target: {
                        value: {
                          nama: label,
                          kota_id: value,
                        },
                        name: "kota",
                      },
                    });
                    handleChange({
                      target: {
                        value: {
                          nama: "",
                          sekolah_id: "",
                        },
                        name: "sekolah",
                      },
                    });
                  }}
                  provinsi_id={form.provinsi.provinsi_id}
                  TextFieldProps={{
                    margin: "normal",
                    // variant: "outlined",
                    disabled: isSubmitting,
                    helperText: error.kota,
                    error: !!error.kota,
                  }}
                />
                <TextField
                  //  variant="outlined"
                  disabled={isSubmitting}
                  id="sekolah"
                  name="sekolah"
                  label="Sekolah*"
                  type="text"
                  // className={classes.textField}
                  value={form.sekolah.nama}
                  className={classes.formInput}
                  onChange={(e) => {
                    let value = {
                      nama: e.target.value,
                      sekolah_id: "",
                    };
                    handleChange({
                      target: {
                        value,
                        name: "sekolah",
                      },
                    });
                  }}
                  helperText={error.sekolah}
                  error={!!error.sekolah}
                  fullWidth
                  margin="normal"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <FormControl
                  className={classes.formInput}
                  disabled={isSubmitting}
                  style={{ width: "100%" }}
                  error={!!error.kelas}
                >
                  <InputLabel id="kelas" shrink>
                    Kelas
                  </InputLabel>
                  <Select
                    id="kelas"
                    name="kelas"
                    value={form.kelas}
                    onChange={handleChange}
                  >
                    <MenuItem value="">
                      <em>Pilih Kelas</em>
                    </MenuItem>
                    <MenuItem value="10">Kelas 10</MenuItem>
                    <MenuItem value="11">Kelas 11</MenuItem>
                    <MenuItem value="12">Kelas 12</MenuItem>
                    <MenuItem value="A">Alumni</MenuItem>
                    <MenuItem value="7">Kelas 7</MenuItem>
                    <MenuItem value="8">Kelas 8</MenuItem>
                    <MenuItem value="9">Kelas 9</MenuItem>
                  </Select>
                </FormControl>
                <div className={classes.btnWrap}>
                  <Button
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    {" "}
                    Lihat Hasil
                  </Button>
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                  <small>
                    This site is protected by reCAPTCHA and the Google
                    <a href="https://policies.google.com/privacy">
                      Privacy Policy
                    </a>{" "}
                    and
                    <a href="https://policies.google.com/terms">
                      Terms of Service
                    </a>{" "}
                    apply.
                  </small>
                </div>
                <div>
                  <p className={classes.smallText}>
                    (*) : “isikan data-data dengan benar untuk keperluan{" "}
                    <span className={classes.redText}>
                      pengiriman e-sertifikat
                    </span>{" "}
                    hasil tes minat bakat Kamu
                  </p>
                </div>
              </form>
            </div>
            {isSubmitting && (
              <div className="pb-popup-wrapper">
                <div className="pb-loading-container">
                  <img src={pbLogo} alt="pakbudi-logo" />
                  <h2>Lagi Loading..</h2>
                  <p>Sabar ya...</p>
                </div>
              </div>
            )}
          </Container>
        </Slide>
      </>
    );
  }

  const statement = statements[indexes[index]];
  return (
    <>
      {appbar}
      <Container className={classes.container} maxWidth="xs" component="main">
        <div className={classes.topMsg}>
          <Typography className={classes.topMsgText} variant="subtitle1">
            Pilih diantara 2 pilihan ini yang paling mencerminkan diri kamu
          </Typography>
        </div>
        <div className={classes.topProgress}>
          <LinearProgress
            variant="determinate"
            value={((index + 1) / 60) * 100}
          />
          <Typography className={classes.progressCount}>
            Pernyataan ke {index + 1} dari {statements.length}
          </Typography>
        </div>
        <div className={classes.certainMsg}>
          <Typography
            variant="caption"
            color="primary"
            className={classes.certainMsgText}
          >
            {index >= 0 && index <= 14 && "Ayo Cari tahu minat dan bakatmu!"}
            {index >= 15 &&
              index <= 29 &&
              "Ayo semangat! Ayo lihat cerminan dirimu!"}
            {index >= 30 && index <= 44 && "Udah setengah jalan lebih, ayo!"}
            {index >= 45 && index <= 60 && "Ayo sedikit lagi selesai!"}
          </Typography>
        </div>
        <div className={classes.statement}>
          <Slide direction={slide.direction} in={slide.in}>
            <FormControl component="fieldset" className={classes.formControl}>
              <RadioGroup
                aria-label="gender"
                name={statement.name}
                value={answers[statement.name] || ""}
                onChange={handleAnswer}
              >
                <FormControlLabel
                  className={classes.option}
                  value={statement.options.op1.value}
                  control={<Radio className={classes.radio} />}
                  label={
                    <>
                      <div className={classes.illustration}>
                        <Img
                          className={classes.statementImg}
                          src={`${baseUrl}${statement.options.op1.illustration}`}
                          alt={statement.options.op1.caption}
                          loader={<ImageLoader width={280} height={160} />}
                        />
                        <span className={classes.statementCaption}>
                          {statement.options.op1.caption}
                        </span>
                      </div>
                    </>
                  }
                />
                <FormControlLabel
                  className={classes.option}
                  value={statement.options.op2.value}
                  control={<Radio className={classes.radio} />}
                  label={
                    <>
                      <div className={classes.illustration}>
                        <Img
                          className={classes.statementImg}
                          src={`${baseUrl}${statement.options.op2.illustration}`}
                          alt={statement.options.op2.caption}
                          loader={<ImageLoader width={280} height={160} />}
                        />
                        <span className={classes.statementCaption}>
                          {statement.options.op2.caption}
                        </span>
                      </div>
                    </>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Slide>
        </div>
      </Container>
    </>
  );
}

export default Game;
