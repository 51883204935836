import React, { useState } from "react";

// import komponen material ui
import Container from "@material-ui/core/Container";
// import Paper from '@material-ui/core/Paper';
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";

// import komponen React Router DOM

// import styles
import { useFirebase } from "../../components/FirebaseProvider";
import { logo } from "../../images/s3";
import useStyles from "./styles";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { siswa } from "../../config/urls";
// buat sebuah fungsi komponen dengan nama NotFound
function Register(props) {
  const classes = useStyles();
  //const email = location.state && location.state.email ? location.state.email : '';
  const { user, functions } = useFirebase();
  const { executeRecaptcha } = useGoogleReCaptcha();
  // Form
  const [form, setForm] = useState({
    email: "",
    password: "",
    ulangi_password: "",
    username: "",
  });

  // Error
  const [error, setError] = useState({
    email: "",
    password: "",
    ulangi_password: "",
    username: "",
  });

  // On change
  const handleChange = (e) => {
    const { name, value } = e.target;

    // if (value === '') {
    //     setSubmitting(true);
    // } else {
    //     setSubmitting(false);
    // }

    setForm({
      ...form,
      [name]: value,
    });

    setError({
      ...error,
      [name]: "",
    });
  };

  // Submit Button
  const [isSubmitting, setSubmitting] = useState(false);

  // Validator error
  const validate = () => {
    const newError = { ...error };
    const { password, ulangi_password, username } = form;

    // if (!email) {
    //     newError.email = 'Email wajib diisi';

    // } else if (!isEmail(email)) {
    //     newError.email = 'Email tidak valid';
    // }

    if (!password) {
      newError.password = "Password wajib diisi";
    }

    if (!ulangi_password) {
      newError.ulangi_password = "Ulangi password wajid diisi";
    } else if (ulangi_password !== password) {
      newError.ulangi_password = "Ulangi password tidak sama dengan password";
    }

    if (!username) {
      newError.username = "Username wajib diisi";
    }
    return newError;
  };

  // On Submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) {
      return null;
    }
    const findErrors = validate();

    if (Object.values(findErrors).some((err) => err !== "")) {
      setError(findErrors);
    } else {
      const token = await executeRecaptcha("tmb_update");
      const verifyRecaptcha = functions.httpsCallable("verifyRecaptcha");

      const recaptcha = await verifyRecaptcha({ token });
      console.log(recaptcha);
//      if (
//        recaptcha.data.action !== "tmb_update" ||
//        recaptcha.data.score <= 0.5
//      ) {
//        setError({ nama: "Terjadi kesalahan! Silahkan coba lagi lain kali" });
//        return false;
//      }

      try {
        setSubmitting(true);

        const tmbUpdate = functions.httpsCallable("tmbUpdate");

        const result = await tmbUpdate({
          email: user.email,
          password: form.password,
          username: form.username,
          recaptcha: recaptcha.data.score,
        });

        window.location = `${siswa}login-by-token/${result.data.customToken}`;
        // await auth.signInWithEmailAndPassword(form.email, form.password);
      } catch (e) {
        setSubmitting(false);
        const newError = {};

        switch (e.code) {
          case "already-exists":
            newError.username = "Username sudah digunakan pengguna lain";
            break;
          default:
            newError.email = "Terjadi Kesalahan Silahkan Coba Lagi";
            break;
        }
        setError(newError);
      }
    }
  };
  const appbar = (
    <AppBar className={classes.appBar} position="static">
      <Toolbar>
        <img src={logo} alt="logo" className={classes.logo} />
      </Toolbar>
    </AppBar>
  );
  return (
    <>
      {appbar}
      <Container maxWidth="xl" component="main" className={classes.mainWrap}>
        <div className={classes.regBody}>
          <Typography className={classes.heroTitle} variant="h4">
            Isi Data Berikut Untuk Melihat Hasil Lengkap Tes Minat Bakatmu
          </Typography>
          <form onSubmit={handleSubmit} noValidate>
            <div className={classes.formWrap}>
              <div className={classes.formRow}>
                <TextField
                  id="email"
                  name="email"
                  label="Alamat Email"
                  className={classes.textField}
                  defaultValue={user.email}
                  InputProps={{
                    readOnly: true,
                  }}
                  required
                />
              </div>
              <div className={classes.formRow}>
                <TextField
                  id="username"
                  name="username"
                  label="Buat Username"
                  className={classes.textField}
                  onChange={handleChange}
                  error={error.username ? true : false}
                  helperText={error.username}
                  disabled={isSubmitting}
                  required
                />
              </div>

              <div className={classes.formRow}>
                <TextField
                  id="password"
                  name="password"
                  label="Password"
                  type="password"
                  onChange={handleChange}
                  className={classes.textField}
                  error={error.password ? true : false}
                  helperText={error.password}
                  disabled={isSubmitting}
                  required
                />
              </div>
              <div className={classes.formRow}>
                <TextField
                  id="ulangi_password"
                  name="ulangi_password"
                  label="Ulangi Password"
                  type="password"
                  onChange={handleChange}
                  className={classes.textField}
                  error={error.ulangi_password ? true : false}
                  helperText={error.ulangi_password}
                  disabled={isSubmitting}
                  required
                />
              </div>
              <div className={classes.btnWrap}>
                <Button
                  disabled={isSubmitting}
                  className={classes.btnTest}
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  {" "}
                  Cek Hasil Detail
                </Button>
              </div>
            </div>
          </form>
        </div>
      </Container>
    </>
  );
}

export default Register;
